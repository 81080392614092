import { Environment } from '@env/environment.interface'

export const environment: Environment = {
  info: {
    appName: 'Admin Panel'
  },
  config: {
    apiUrl: 'https://api.kubewallet.io/api/'
  }
}
